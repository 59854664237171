type Props = {
  children: React.ReactNode;
  dataCy?: string;
};

export const H1 = ({ children, dataCy }: Props): JSX.Element => (
  <h1
    className={`m-0 font-firs text-[38px] font-semibold leading-[42px] tracking-[-0.22px] lg:text-[80px] lg:leading-[90px]`}
    data-cy={dataCy}
  >
    {children}
  </h1>
);

/**
 * Used in articles where we want to use H1 for SEO purposes
 */
export const H1Small = ({ children, dataCy }: Props): JSX.Element => (
  <h1
    className={`m-0 font-firs text-[24px] font-semibold leading-[32px] tracking-[-0.22px] lg:text-[38px] lg:leading-[42px]`}
    data-cy={dataCy}
  >
    {children}
  </h1>
);

export const H2 = ({ children, dataCy }: Props): JSX.Element => (
  <h2
    className={`m-0 font-firs text-[32px] font-semibold leading-[40px] tracking-[-0.22px] lg:text-[64px] lg:leading-[70px]`}
    data-cy={dataCy}
  >
    {children}
  </h2>
);

// As of 29/03/23, we want another H1 for new website designs introduced alongside the
// redesign for the GLP-1 programme launch
export const H2Small = ({ children, dataCy }: Props): JSX.Element => (
  <h2
    className={`m-0 font-firs text-[24px] font-semibold leading-[32px] tracking-[-0.22px] lg:text-[50px] lg:leading-[64px]`}
    data-cy={dataCy}
  >
    {children}
  </h2>
);

export const H3 = ({ children, dataCy }: Props): JSX.Element => (
  <h3
    className={`m-0 font-firs text-[24px] font-semibold leading-[32px] tracking-[-0.22px] lg:text-[32px] lg:leading-[40px]`}
    data-cy={dataCy}
  >
    {children}
  </h3>
);

export const H3Small = ({ children, dataCy }: Props): JSX.Element => (
  <h3
    className={`m-0 font-firs text-[22px] font-medium leading-[24px] tracking-[-0.22px] `}
    data-cy={dataCy}
  >
    {children}
  </h3>
);

export const H4 = ({ children, dataCy }: Props): JSX.Element => (
  <h4
    className={`m-0 font-firs text-[18px] font-medium leading-[24px] tracking-[-0.22px] lg:text-[24px] lg:leading-[30px]`}
    data-cy={dataCy}
  >
    {children}
  </h4>
);

export const H5 = ({ children, dataCy }: Props): JSX.Element => (
  <h5
    className={`m-0 font-firs text-[16px] font-medium leading-[19px] tracking-[-0.22px] lg:text-[18px] lg:leading-[22px]`}
    data-cy={dataCy}
  >
    {children}
  </h5>
);

export const H6 = ({ children, dataCy }: Props): JSX.Element => (
  <h6
    className={`m-0 font-firs text-[14px] font-medium leading-[18px] tracking-[-0.22px] lg:text-[16px] lg:leading-[20px]`}
    data-cy={dataCy}
  >
    {children}
  </h6>
);
